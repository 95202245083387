import mixpanel from 'mixpanel-browser';

export type QueryParams = {
  [key: string]: string;
};

export const getQueryParams = (url_string: string) => {
  const url = new URL(url_string);
  const queryParams: QueryParams = {};
  for (const [key, value] of url.searchParams.entries()) {
    queryParams[key] = value?.trim();
  }
  return queryParams;
};

export const query = getQueryParams(location.href);
export const search = location.search;

export const queryParamsToObject = (searchParams: URLSearchParams) => {
  const queryParams: QueryParams = {};
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value?.trim();
  }
  return queryParams;
};

export const getUtmQueryParamsObject = (searchParams: URLSearchParams) => {
  const query = queryParamsToObject(searchParams);
  const utmParams: { [key: string]: string } = {};

  Object.keys(query).forEach((key) => {
    if (key.startsWith('utm')) {
      utmParams[key] = query[key];
    }
  });

  return utmParams;
};

export const trackMixpanelEvent = (eventName: string, properties = {}) => {
  try {
    mixpanel?.track(eventName, properties);
  } catch (e) {}
};

