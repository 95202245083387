import cookie from 'cookie';

import { device_store } from '@/models/root/device';
import { base_domain, productionRules } from '@/ve-utils/cookie_utils';
import { message } from 'antd';

export const cookies = cookie.parse(document.cookie);

export type Client = 'web' | 'magic' | 'ghost';

type JWT = {
  'access-token': string;
  uid: string;
  client: Client;
};

export const jwt: JWT = cookies.jwt && JSON.parse(cookies.jwt);
export const isGhost = () => jwt.client === 'ghost';

type Method = 'get' | 'post' | 'put' | 'delete' | 'upload' | 'PATCH';
type Options = {
  method?: Method;
  body?: RequestInit['body'];
  data_type?: 'json' | 'blob';
};

//use this post login
export const fetcher = async (url: string, options?: Options) => {
  if (!jwt) {
    location.href = '/auth/login';
  }

  const {
    method = 'get',
    body,
    data_type = 'json',
    showError = true,
  } = options || {};

  type HeadersMap = Record<Method, object>;

  const headersMap: HeadersMap = {
    get: jwt,
    post: {
      'content-type': 'application/json',
      ...jwt,
    },
    put: {
      'content-type': 'application/json',
      ...jwt,
    },
    delete: {
      'content-type': 'application/json',
      ...jwt,
    },
    upload: {
      // "content-type": "multipart/form-data",
      ...jwt,
    },
    PATCH: {
      'content-type': 'application/json',
      ...jwt,
    },
  };
  const headers: HeadersInit = new Headers(headersMap[method] as HeadersInit);
  if (['post', 'put', 'PATCH'].includes(method) && typeof body === 'object') {
    //sometimes put request has form data
    headers.delete('content-type');
  }

  const device_header = device_store.header;
  if (device_header) {
    headers.append('device', device_header);
  }
  const res = await window.fetch(url, {
    method: method === 'upload' ? 'post' : method,
    headers,
    body,
  });
  const status = res.status;
  if ([401].includes(status)) {
    logout();
  }
  const data = (await res[data_type]().catch((err) => undefined)) || {};
  return { data, status, ok: res.ok };
};

export const getJWTTokenFromResponseHeaders = (headers: any) => {
  return {
    'access-token': headers['access-token'],
    uid: headers['uid'],
    client: headers['client'],
    admin_uid: headers['admin-uid'],
    'X-Client-Id': headers['x-client-id'],
  };
};
export const setJWTFromResponseHeaders = (headers: any) => {
  const tokenHeader = getJWTTokenFromResponseHeaders(headers);
  setJWT(tokenHeader);
};

export const setJWT = (value: any) => {
  const nextYear = new Date();
  nextYear.setFullYear(nextYear.getFullYear() + 1);
  const cookie_string = cookie.serialize('jwt', JSON.stringify(value), {
    domain: base_domain,
    path: '/',
    ...productionRules,
    expires: nextYear,
  });
  document.cookie = cookie_string;
};

export const removeJWT = () => {
  const cookie_string = cookie.serialize('jwt', 'undefined', {
    domain: base_domain,
    path: '/',
    ...productionRules,
    expires: new Date(1970, 1, 1, 0, 0, 1),
  });
  document.cookie = cookie_string;
  localStorage.removeItem('applicationId');
  localStorage.removeItem('applicationType');
};

export const logout = (errorMsg?: string) => {
  if (errorMsg) {
    message.error(errorMsg);
  }
  removeJWT();
  location.href = '/auth/login';
};
